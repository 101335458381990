import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Popover,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import L from "leaflet";
import style from "./Dashboard.module.css";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";
import { useLoaderData, useRevalidator } from "react-router-dom";
import Api from "../../api";
import { Chart } from "chart.js";
import SiteContext from "../../context/siteContext";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import dayjs from "dayjs";
import _ from "lodash";
import UserContext from "../../context/userContext";
import { DashboardTable } from "./DashboardTable";
import {
  get_sector_diagram_config,
  greenStatusColor,
  redStatusColor,
} from "./diagram_config";
import Footer from "./Footer";
import { IContactPerson, IDashTableLabel, ISite } from "../../interface";

export async function dashboardLoader() {
  return Api.get("/dashboard/");
}

const default_config = [
  "isp",
  "contact_person",
  "last_sync",
  "firewall_traffic",
];

export default function DashboardMain() {
  const data: any = useLoaderData();
  const { setSnackbarText } = useContext(UserContext);
  const { setDashboardData } = useContext(SiteContext);

  const [searchText, setSearchText] = useState("");
  const [allTableCols, setAllTableCols] = useState<IDashTableLabel[]>([]);
  const [activeTableCols, setActiveTableCols] =
    useState<string[]>(default_config);
  const [filteredSites, setFilteredSites] = useState<ISite[]>([]);
  const [hasOpenedFilter, setHasOpenedFilter] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [doughchartData, setDoughchartData] = useState<any>();
  const [mapSiteCoords, setMapSiteCoords] = useState<any>();

  const revalidator = useRevalidator();

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setHasOpenedFilter(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl == null) {
      if(data.selected_table_labels?.length > 0){
        setActiveTableCols(
          data.selected_table_labels?.map((col: any) => col.slug)
        );  
      }
      setAllTableCols(data.table_labels);
    }
  }, [data]);

  useEffect(() => {
    if (anchorEl == null && hasOpenedFilter) {
      handleSaveColumnSetting();
    }
  }, [anchorEl]);

  useEffect(() => {
    const sites = _.get(data, "sites", []);
    const filtered_sites = [];
    for (const site of sites) {
      if (
        searchText.length > 0 &&
        !site.name.toLowerCase().includes(searchText.toLowerCase())
      ) {
        continue;
      }
      filtered_sites.push(site);
    }
    setFilteredSites(filtered_sites);
  }, [data.sites, searchText]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      revalidator.revalidate();
    }, 7000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setDashboardData(null);
  }, []);

  useEffect(() => {
    let site_count = data.sites.length;
    let active_sites = data.sites.filter((x: any) => x.is_operative).length;
    let chartData = [active_sites, site_count - active_sites];
    if (!_.isEqual(chartData, doughchartData)) {
      setDoughchartData(chartData);
    }
    // map data
    const temp_map_data = data.sites?.map((element: any) =>
      _.pick(element, ["latitude", "longitude", "is_operative"])
    );

    if (!_.isEqual(temp_map_data, mapSiteCoords)) {
      setMapSiteCoords(temp_map_data);
    }
  }, [data]);

  useEffect(() => {
    const dasbboardsettings = data.company.settings?.dashboard;
    const coords = dasbboardsettings?.map_coords ?? [65, 7.8933];
    const zoom = dasbboardsettings?.map_zoom ?? 4.4;

    const map = L.map("l_map", {
      attributionControl: false,
    }).setView(coords, zoom);
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(
      map
    );

    mapSiteCoords?.forEach((element: any) => {
      const lat = element.latitude;
      const long = element.longitude;
      if (lat && long) {
        L.circleMarker([lat, long], {
          color: element.is_operative ? greenStatusColor : redStatusColor,
          fillColor: element.is_operative ? greenStatusColor : redStatusColor,
          fillOpacity: 0.6,
          radius: 10,
        }).addTo(map);
      }
    });

    return () => {
      map.remove();
    };
  }, [mapSiteCoords]);

  useEffect(() => {
    const config: any = get_sector_diagram_config(doughchartData);
    const ctx: any = document.getElementById("operative_sites_chart");
    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, [doughchartData]);

  function downloadSiteListToCsv() {
    const custom_fields = _.filter(allTableCols, { is_derived_value: false });
    let csv_data = data?.sites.map((site: ISite) => {
      let items = [
        site.name,
        site.internet_provider.name,
        site.contact_person
          ?.map(
            (person: IContactPerson) =>
              person.first_name + " " + person.last_name
          )
          .join(","),
        site.last_sync_datetime,
      ];

      for (const item of custom_fields) {
        const res = _.find(site.extra_fields, { slug: item.slug });
        items.push(res ? res.value_formatted : "");
      }

      return items
        .concat()
        .map(String)
        .map((v: String) => v.replaceAll('"', '""'))
        .map((v: String) => `"${v}"`)
        .join(",");
    });

    const header =
      '"Site","ISP","Contact","Last Sync",' +
      custom_fields.map((c) => `"${c.value}"`).join(",");
    console.log(header);
    csv_data.unshift(header);

    csv_data = csv_data.join("\r\n");

    const blob = new Blob([csv_data], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "sitelist.csv");
    pom.click();
  }

  function handleFilterColum(item: any, checked: Boolean) {
    if (checked) {
      setActiveTableCols(_.without(activeTableCols, item.slug));
    } else {
      const temp = [...activeTableCols];
      temp.push(item.slug);
      setActiveTableCols(temp);
    }
  }

  async function handleSaveColumnSetting() {
    const data = {
      cols: activeTableCols,
    };
    try {
      await Api.post("/dashboard/table_colums/", data);
    } catch (e) {}

    setSnackbarText("Saved filter settings");
    handleClosePopover();
  }

  return (
    <>
      <Container
        maxWidth="xl"
        style={{ marginTop: "1rem", minHeight: "calc(100vh - 10rem)" }}
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="h6" sx={{ pl: 0, pt: 0 }}>
              Network Dashboard
            </Typography>
          </Grid>
          <Grid item md={6}>
            <div className={style.tiles}>
              <Grid container alignItems={"center"}>
                <Grid item md={5}>
                  <Box sx={{ padding: "1rem" }}>
                    <canvas id="operative_sites_chart" width={"100%"} />
                    <Stack alignItems={"center"}>
                      <Typography variant="h6" color="text.secondary">
                        {data.sites.filter((x: any) => x.is_operative).length} /{" "}
                        {data.sites.length} Operative
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item md={7} sx={{ height: "23" }}>
                  <Box sx={{ overflowY: "scroll", height: "23rem" }}>
                    <Timeline
                      sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                          flex: 0.2,
                        },
                      }}
                    >
                      {data.events.map((event: any) => {
                        let color = "primary" as any;
                        if (event.type === "w") {
                          color = "warning";
                        }
                        return (
                          <TimelineItem key={event.created}>
                            <TimelineOppositeContent color="text.secondary">
                              <Box sx={{ width: "4rem", textAlign: "center" }}>
                                {dayjs(event.created).format("HH:mm")}
                                <br />
                                {dayjs(event.created).format("MMM DD")}
                              </Box>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color={color} />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Typography color="text.secondary">
                                {event.site.name}
                              </Typography>
                              {event.message}
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={style.tiles}>
              <div id="l_map" style={{ height: "23rem" }}></div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          sx={{ mt: 2, backgroundColor: "white" }}
        >
          <Grid item>
            <Stack
              direction={"row"}
              alignItems={"flex-end"}
              justifyContent={"space-between"}
              sx={{ p: 2 }}
            >
              <TextField
                id="standard-basic"
                label="Search"
                size="small"
                sx={{}}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
              <Box>
                <Button
                  startIcon={<SettingsIcon />}
                  sx={{ pr: 2 }}
                  color="inherit"
                  onClick={handleClickPopover}
                >
                  Settings
                </Button>
                <Popover
                  open={Boolean(anchorEl)}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  anchorEl={anchorEl}
                >
                  <Box sx={{ p: "1rem" }}>
                    <FormGroup>
                      {data?.table_labels?.map(
                        (table_field: IDashTableLabel) => {
                          const checked = _.includes(
                            activeTableCols,
                            table_field.slug
                          );
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  onClick={() =>
                                    handleFilterColum(table_field, checked)
                                  }
                                  checked={checked}
                                />
                              }
                              label={table_field.value}
                            />
                          );
                        }
                      )}
                    </FormGroup>
                  </Box>
                </Popover>
                <Button
                  startIcon={<DownloadIcon />}
                  color="inherit"
                  onClick={downloadSiteListToCsv}
                >
                  Export
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item>
            <DashboardTable
              sites={filteredSites}
              allTableCols={allTableCols}
              activeTableCols={activeTableCols}
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
