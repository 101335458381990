import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { Button } from "@mui/material";
import { IContactPerson, IDashTableLabel, ISite } from "../../interface";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import MiniChart from "./MiniChart";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { greenStatusColor, redStatusColor } from "./diagram_config";

export const DashboardTable = ({
  allTableCols,
  activeTableCols,
  sites,
}: {
  allTableCols: IDashTableLabel[];
  activeTableCols: string[];
  sites: ISite[];
}) => {
  const navigate = useNavigate();

  function getDisplayNameForLastSync(row: any) {
    let lastSync = "";
    if (row.last_sync_datetime) {
      const now = dayjs();
      const pastTime = dayjs(row.last_sync_datetime);
      let last_sync = "";
      let sec = now.diff(pastTime, "second");
      let minutes = now.diff(pastTime, "minutes");
      let hours = now.diff(pastTime, "hours");
      let days = now.diff(pastTime, "days");

      if (days > 1) {
        lastSync = days + " days";
      } else if (hours > 1) {
        lastSync = hours + " hours";
      } else if (minutes > 1) {
        lastSync = minutes + " min";
      } else {
        lastSync = sec + " sec";
      }
    }
    return lastSync;
  }

  const get_dynamic_comp: any = (site: ISite, slug: string) => {
    if (slug === "isp") {
      return <TableCell key={slug}>{site.internet_provider?.name}</TableCell>;
    } else if (slug === "contact_person") {
      return (
        <TableCell key={slug}>
          {site.contact_person
            .map(
              (person: IContactPerson) =>
                person.first_name + " " + person.last_name
            )
            .join(", ")}
        </TableCell>
      );
    } else if (slug === "last_sync") {
      return <TableCell key={slug}>{getDisplayNameForLastSync(site)}</TableCell>;
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={"1rem"}>Status</TableCell>
            <TableCell>Name</TableCell>
            {allTableCols.map((header: any) => {
              if (
                _.includes(activeTableCols, header.slug) &&
                header.slug !== "firewall_traffic"
              ) {
                return <TableCell>{header.value}</TableCell>;
              }
            })}
            {_.includes(activeTableCols, "firewall_traffic") && (
              <TableCell></TableCell>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sites.map((site: ISite, i: number) => {
            let cells = [
              <TableCell key={"status"}>
                <RadioButtonCheckedIcon
                  sx={{
                    color: site.is_operative
                      ? greenStatusColor
                      : redStatusColor,
                  }}
                />
              </TableCell>,
              <TableCell key={"name"}>{site.name} {site.city ? `(${site.city})` : ""} </TableCell>,
            ];

            for (let item of allTableCols) {
              if (_.includes(activeTableCols, item.slug)) {
                if (item.is_derived_value) {
                  cells.push(get_dynamic_comp(site, item.slug));
                } else {
                  const table_value = _.find(site?.extra_fields, {
                    slug: item.slug,
                  });
                  cells.push(
                    <TableCell key={item.id}>{table_value?.value_formatted}</TableCell>
                  );  
                }
              }
            }

            if (_.includes(activeTableCols, "firewall_traffic")) {
              cells.push(
                <TableCell key={"traffic"}>
                  <MiniChart
                    series={site.wan_traffic_firewall?.map((x: any) => x.inpass)}
                    count={i}
                  />
                </TableCell>
              );
            }

            cells.push(
              <TableCell key={"submit"} align="right" sx={{ maxWidth: "7rem" }}>
                <Button
                  variant="text"
                  onClick={() => navigate(`/sites/${site.id}/firewalls`)}
                >
                  Go To site
                </Button>
              </TableCell>
            );

            return <TableRow key={site.name}>{cells}</TableRow>;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
